import { RootState } from '../index';

export function feedbackPending(state: RootState) {
  return state.feedback.request.status === 1;
}

export function feedbackLoaded(state: RootState) {
  return state.feedback.request.status === 2;
}

export function feedbackFailure(state: RootState) {
  return state.feedback.request.error;
}

export function feedbackData(state: RootState) {
  return state.feedback;
}
