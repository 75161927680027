import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Skeleton } from '@mui/material';
import { selectAdmin } from '@/store/admin/selectors';
import { initAdmin, adminLogout } from '@/store/admin/slicer';
import { getUser, userFailure } from '@/store/profile/selectors';
import { initUser, changeUser, cookieAgreement } from '@/store/profile/slicer';
import { chatsFailure, messagesFailure } from '@/store/messages/selectors';
import { sympathiesFailure } from '@/store/sympathies/selectors';
import { orderFailure } from '@/store/orders/selectors';
import { getOrder } from '@/store/orders/slicer';
import { feedbackData } from '@/store/feedback/selectors';
import { addVisitDb } from '@/store/feedback/slicer';
import { menuLinks } from '@/utils/constants';
import { checkSubscription, getDates, getElement } from '@/utils/methods';

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refPay = useRef(true);
  const { pathname } = useLocation();
  const { authed: userAuth, activated, cookie, currUser } = useSelector(getUser, shallowEqual);
  const { authed: adminAuth, currAdmin } = useSelector(selectAdmin, shallowEqual);
  const { visit } = useSelector(feedbackData, shallowEqual);
  const profileError = useSelector(userFailure, shallowEqual);
  const chatsError = useSelector(chatsFailure, shallowEqual);
  const messagesError = useSelector(messagesFailure, shallowEqual);
  const sympathiesError = useSelector(sympathiesFailure, shallowEqual);
  const orderError = useSelector(orderFailure, shallowEqual);
  const [errorSlicer, setErrorSlicer] = useState('');

  const regPath = pathname.match(/\/admin\/[a-z]+/);

  useEffect(() => {
    dispatch(initUser());
    if (visit?.date !== getDates().day + getDates().month) dispatch(addVisitDb());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { loggedIn } = currUser;
    if (userAuth && currUser.id && loggedIn && adminAuth) dispatch(initAdmin(currUser.id));
    //if (!userAuth && adminAuth) dispatch(adminLogout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currUser.id, currUser.loggedIn, userAuth, adminAuth]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;

    const allowPath = pathname === '/questionnaires' || pathname === '/sympathies';

    if (allowPath && !activated) return navigate('/');
    //
  }, [pathname, activated, navigate]);

  useEffect(() => {
    const { subscription } = currUser;
    if (!!currUser.id && subscription?.paid) {
      const obj = checkSubscription(subscription);
      const paid = obj.subscription.paid;
      const amountDays = obj.subscription.amountDays;
      //console.log(obj);
      if (paid !== subscription.paid || amountDays !== subscription.amountDays)
        dispatch(changeUser(obj));
    }

    if (!!currUser.id && refPay.current && subscription?.pending && !subscription?.paid) {
      dispatch(getOrder(subscription, currUser.id));
    }

    if (!!currUser.id && refPay.current) refPay.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currUser.subscription, pathname]);

  useEffect(() => {
    const menuPath =
      pathname === '/profile' ||
      pathname === '/questionnaires' ||
      pathname === '/messages' ||
      pathname === '/sympathies' ||
      pathname === '/settings';

    menuLinks.forEach(link => {
      if (pathname === link.path) {
        document.title = link.title;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const headEl = document.head.children as any;
        headEl.description.content = link.description;
      }
    });

    if (menuPath && (!userAuth || !currUser)) {
      navigate('/');
      setTimeout(() => {
        setErrorSlicer('');
      }, 600);
    }
    if (pathname === '/' && !!userAuth) {
      // && !!currUser.id
      navigate('/profile');
      setTimeout(() => {
        document.body.style.overflow = 'auto';
      }, 100);
    }

    if (menuPath) getElement('footer').el.classList.add('hidden');
    else getElement('footer').el.classList.remove('hidden');
  }, [userAuth, currUser, pathname, navigate]);

  useEffect(() => {
    if (!!profileError) setErrorSlicer(profileError);
    if (!!chatsError) setErrorSlicer(chatsError);
    if (!!messagesError) setErrorSlicer(messagesError);
    if (!!sympathiesError) setErrorSlicer(sympathiesError);
    if (!!orderError) setErrorSlicer(orderError);
  }, [profileError, chatsError, messagesError, sympathiesError, orderError]);

  const handleSetCookie = () => {
    //localStorage.setItem('cookie', 'true');
    // const obj = {
    //   cookie: true,
    // };
    // dispatch(changeUser(obj));
    dispatch(cookieAgreement());
  };

  const handleAdminExit = () => {
    dispatch(adminLogout());
  };

  return (
    <header>
      {adminAuth && currUser?.loggedIn && (
        <div className="headerAdmin">
          <div className="container">
            <nav className="headerAdmin__nav">
              <a href="/" className={!regPath ? 'link link_active' : 'link'}>
                Сайт
              </a>
              <a href="/admin/lk" className={regPath ? 'link link_active' : 'link'}>
                Админ панель
              </a>
            </nav>

            <div className="headerAdmin__info">
              <div className="login">
                {!currAdmin.id ? (
                  <span className="mainSkeleton">
                    <Skeleton variant="text" width="99%" height="21px" animation="wave" />
                  </span>
                ) : (
                  <span>{currAdmin.name}</span>
                )}
              </div>
              <button className="exitBtn" onClick={handleAdminExit}>
                Выйти
              </button>
            </div>
          </div>
        </div>
      )}

      {pathname !== '/' && !regPath && (
        <div id="show-header" className="headerLogo container">
          <a href="/" className="logo">
            <img src="/images/background/logo.svg" alt="logo" />
          </a>
        </div>
      )}

      {errorSlicer && !!currUser?.id && (
        <Alert severity="error" className="mainAlert">
          Ошибка загрузки <br />
          <span className="textErr">{errorSlicer}</span>
        </Alert>
      )}

      {!cookie && (
        <div className="mainPopup mainPopup_bottom">
          <div className="cookies">
            <p className="text">
              Мы используем cookie, чтобы сделать сайт лучше. Оставаясь на OneClickMate, вы
              принимаете
              <a href="/user-policy" target="_blank" className="link">
                пользовательское соглашение.
              </a>
            </p>
            <button className="btn" onClick={handleSetCookie}>
              Согласен
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
