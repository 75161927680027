// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBnoIRRfuAHHT4uUJmQHkyHYFA8n6dolS8',
  authDomain: 'oneclickmate-7cba8.firebaseapp.com',
  databaseURL: 'https://oneclickmate-7cba8-default-rtdb.firebaseio.com',
  projectId: 'oneclickmate-7cba8',
  storageBucket: 'oneclickmate-7cba8.appspot.com',
  messagingSenderId: '945848751534',
  appId: '1:945848751534:web:da8270f2cd6eef56bda43f',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getDatabase();

export const login = async (email: string, pass: string) => {
  await signInWithEmailAndPassword(auth, email, pass);
};

export const signUp = async (email: string, pass: string) => {
  await createUserWithEmailAndPassword(auth, email, pass);
};

export const logout = async () => {
  await signOut(auth);
};
