import { RootState } from '../index';

export function selectSympathies(state: RootState) {
  return state.sympathies;
}

export function sympathiesPending(state: RootState) {
  return state.sympathies.request.status === 1;
}

export function sympathiesLoaded(state: RootState) {
  return state.sympathies.request.status === 2;
}

export function sympathiesFailure(state: RootState) {
  return state.sympathies.request.error;
}

export function sympathiesMatch(state: RootState) {
  return state.sympathies.requestMatch.status === 4;
}

export function citiesPending(state: RootState) {
  return state.sympathies.requestData.status === 1;
}

export function citiesFailure(state: RootState) {
  return state.sympathies.requestData.error;
}
