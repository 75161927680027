import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Alert } from '@mui/material';
import { feedbackLoaded } from '@/store/feedback/selectors';
import FeedbackModal from '@/components/FeedbackModal';
import { YouTube } from '@/assets/images/buttonIcons';

const Footer: React.FC = () => {
  const { pathname } = useLocation();
  const loaded = useSelector(feedbackLoaded, shallowEqual);
  const [openModal, setOpenModal] = useState(false);
  const regPath = pathname.match(/\/admin\/[a-z]+/);
  const currentYear = new Date().getFullYear();

  const handleFeedbackModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpenModal(true);
  };

  return (
    <>
      {!regPath ? (
        <footer>
          <div className="container">
            <div className="footer-list">
              <div className="footer-column footer-list__footer-column">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/help"
                  className="footer-link footer-column__footer-link"
                >
                  Помощь
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="#"
                  className="footer-link footer-column__footer-link"
                  onClick={handleFeedbackModal}
                >
                  Обратная связь
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/rules"
                  className="footer-link footer-column__footer-link"
                >
                  Правила сайта
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/contacts"
                  className="footer-link footer-column__footer-link"
                >
                  Контакты
                </a>
              </div>

              <div className="footer-column footer-list__footer-column">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/policy"
                  className="footer-link footer-column__footer-link"
                >
                  Положение о защите персональных данных
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/user-policy"
                  className="footer-link footer-column__footer-link"
                >
                  Пользовательское соглашение
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/safety"
                  className="footer-link footer-column__footer-link"
                >
                  Советы безопасности
                </a>
              </div>

              <div className="footer-column--last footer-list__footer-column">
                <img src="/images/background/logo.svg" width="100%" height="100%" alt="logo" />

                <div className="footer-column__footer-img">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://t.me/oneclickmate"
                    aria-label="go to telegram"
                  >
                    <div className="footer-img--telegram">
                      <svg
                        className="telegram-svg"
                        width="40"
                        height="34"
                        viewBox="0 0 40 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id="tg"
                          d="M15.9139 22.2684L30.4159 32.9031C31.9198 33.8699 33.2088 33.3328 33.6385 31.3992L39.5467 3.6844C40.0838 1.32112 38.5799 0.2469 36.9686 0.998854L2.48618 14.3192C0.230322 15.1785 0.230322 16.575 2.05649 17.1121L10.9725 19.9051L31.3827 6.90706C32.3495 6.36995 33.2088 6.58479 32.5643 7.33674"
                          fill="#F6FBFE"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://vk.com/oneclickmate"
                    aria-label="go to vk.com"
                  >
                    <div className="footer-img--vk">
                      <svg
                        className="vk-svg"
                        width="41"
                        height="25"
                        viewBox="0 0 41 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id="vk"
                          d="M22.4337 23.9936C22.9708 23.8862 23.9376 23.6713 23.9376 22.3822C23.9376 22.3822 23.8302 19.1596 25.3341 18.7299C26.838 18.3002 28.7716 21.8451 30.8126 23.2416C32.3165 24.2084 33.4981 24.101 33.4981 24.101L38.9766 23.9936C38.9766 23.9936 41.7696 23.7787 40.4805 21.5229C40.3731 21.308 39.5138 19.9115 36.2911 17.0112C32.961 13.7885 33.4981 14.3256 37.4727 8.84709C39.9434 5.51701 41.0177 3.47599 40.6954 2.72404C40.2657 1.97209 38.547 2.07951 38.547 2.07951H32.4239C31.7794 2.07951 31.4571 2.18693 31.1348 2.72404C31.1348 2.72404 30.168 5.40959 28.879 7.55803C26.1934 12.1772 25.1192 12.392 24.5821 12.0697C23.6153 11.5326 23.8302 9.49162 23.8302 8.09514C23.8302 3.26115 24.5821 1.5424 22.4337 1.11271C21.0372 0.897869 16.0958 0.683025 14.5919 1.43498C13.8399 1.86467 13.4102 2.61662 13.7325 2.72404C14.0548 2.72404 15.0216 2.83146 15.5587 3.47599C16.418 4.87248 16.5255 11.5326 15.3438 12.1772C13.7325 13.3588 9.65047 5.51701 8.68367 2.93888C8.46883 2.29435 8.14656 2.18693 7.39461 1.97209H1.48641C0.841875 1.97209 -0.124922 2.07951 0.304766 3.36857C3.20516 9.3842 9.22078 24.101 20.0704 23.9936H22.4337Z"
                          fill="#F6FBFE"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/@OneClickMate"
                    aria-label="go to youtube.com"
                  >
                    <div className="footer-img--youtube">{YouTube}</div>
                  </a>
                </div>
              </div>
            </div>

            <p className="footer-copy">
              © OneClickMate, {currentYear}{' '}
              <span className="mobileText">
                Знакомства для брака и серьезных отношений
                <br />
                Сервис предназначен для лиц старше 21 года
              </span>
            </p>

            <a href="https://beffect.ru" target="_blank" className="bELogo" rel="noreferrer">
              <p className="text">При поддержке Butterfly Effect</p>
            </a>
          </div>

          <FeedbackModal
            settings={{
              openModal: openModal,
              setOpenModal: setOpenModal,
            }}
          />

          {loaded && (
            <Alert severity="success" className="mainAlert">
              Ваше обращение отправлено
            </Alert>
          )}
        </footer>
      ) : (
        <footer style={{ display: 'none' }}></footer>
      )}
    </>
  );
};

export default Footer;
