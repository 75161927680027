import { RootState } from '../index';

export function selectMessages(state: RootState) {
  return state.messages;
}

export function chatsPending(state: RootState) {
  return state.messages.requestChats.status === 1;
}

export function chatsLoaded(state: RootState) {
  return state.messages.requestChats.status === 2;
}

export function chatsFailure(state: RootState) {
  return state.messages.requestChats.error;
}

export function messagesPending(state: RootState) {
  return state.messages.requestMessages.status === 1;
}

export function messagesLoaded(state: RootState) {
  return state.messages.requestMessages.status === 2;
}

export function messagesFailure(state: RootState) {
  return state.messages.requestMessages.error;
}

export function banMsgPending(state: RootState) {
  return state.messages.requestMessages.status === 5;
}

export function banMsgLoaded(state: RootState) {
  return state.messages.requestMessages.status === 6;
}
