/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { onValue, ref, set, update } from 'firebase/database';
import { auth, db } from '../../services/firebase';
import { AppDispatch } from '@store/index';
import { Order, Subscription } from '@/utils/mainTypes';
import { getCodeDb } from '@/utils/methods';

const initialState = {
  orders: [],
  payment: null,
  request: {
    status: 0,
    error: null,
  },
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, { payload }) => {
      state.orders = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    setPayment: (state, { payload }) => {
      state.payment = payload;
      // state.request.status = 2;
      // state.request.error = null;
    },
    pending: state => {
      state.request.status = 1;
    },
    success: state => {
      state.request.status = 2;
    },
    failure: (state, { payload }) => {
      state.request.status = 3;
      state.request.error = payload;
    },
    clearPayment: state => {
      state.payment = null;
    },
    clear: state => {
      state.orders = [];
      state.request.status = 0;
      state.request.error = null;
    },
  },
});

const { pending, success, failure, setPayment, clearPayment, clear } = ordersSlice.actions;
export default ordersSlice.reducer;

// export const initOrdersDb = (userId: number) => (dispatch: AppDispatch) => {
//   dispatch(pending());
//   //const id = auth.currentUser.uid;
//   const dbRef = ref(db, `orders/${userId}`);
//   onValue(
//     dbRef,
//     snapshot => {
//       const data = snapshot.val();
//       dispatch(setOrders(Object.values(data || {})));
//     },
//     err => {
//       console.log(err);
//       dispatch(failure('Ошибка: ' + err.message.slice(16)));
//     },
//   );
// };

export const sendOrder =
  (sub: Subscription, order: Order, userId: number) => async (dispatch: AppDispatch) => {
    dispatch(pending());
    const authId = auth.currentUser.uid;
    let orderId = null;
    //const formData = new FormData();

    onValue(
      ref(db, 'orders'),
      async snapshot => {
        const list = Object.values(snapshot.val() || {});
        const lastOrder = list[list.length - 1] as Order;
        const randomId = 0 + Date.now().toString().slice(10);
        orderId = lastOrder?.id + 1 || randomId;
        //console.log(orderId);
        const name = `Подписка на сервис OneClickMate ${order.description}`;
        const text = `Заказ № ${orderId}: Подписка на сервис OneClickMate ${order.description}`;
        //const text = `Заказ № ${orderId}: Подписка на ${order.description}`;

        const dbRefOrder = ref(db, `orders/${orderId}`);
        const newOrder = {
          id: orderId,
          userId,
          date: sub.date,
          deadline: sub.deadline,
          price: order.price,
          paid: false,
          description: text,
        };
        set(dbRefOrder, newOrder)
          .then(() => {
            //
          })
          .catch(err => {
            console.log(err);
            dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
          });

        const dbRefUser = ref(db, `users/${authId}`);
        const newSub = {
          subscription: { ...sub, orderId },
        };
        update(dbRefUser, newSub)
          .then(() => {
            //
          })
          .catch(err => {
            console.log(err);
            dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
          });

        const payment = {
          orderId,
          userId,
          name,
          variant: sub.variant,
          //price: order.price,
          text,
        };
        dispatch(setPayment(payment));

        // formData.append('orderId', String(orderId));
        // formData.append('userId', String(userId));
        // formData.append('price', order.price);
        // formData.append('description', text);

        // try {
        //   const req = await fetch('/scripts/order/payment.php', {
        //     method: 'POST',
        //     //headers: { 'Content-Type': 'multipart/form-data' },
        //     body: formData,
        //     mode: 'no-cors',
        //   });
        //   console.log(req);
        //   const resText = await req.text();
        //   console.log(resText);
        //   console.log(resText.toString);
        //   //console.log(JSON.parse(resText));
        //   console.log(JSON.stringify(resText));

        //   if (req.status === 204) {
        //     //dispatch(success());
        //     dispatch(failure('Ошибка оформления заказа, попробуйте ещё раз'));
        //   } // else navigate('/profile');
        // } catch (err) {
        //   console.log(err);
        //   dispatch(failure('Ошибка: ' + err));
        // }
      },
      err => {
        console.log(err);
        dispatch(failure('Ошибка: ' + getCodeDb(err.name)));
      },
      {
        onlyOnce: true,
      },
    );
  };

export const getOrder = (sub: Subscription, userId: number) => async (dispatch: AppDispatch) => {
  dispatch(pending());
  const authId = auth.currentUser.uid;
  const dbRefOrder = ref(db, `orders/${sub.orderId}`);
  const dbRefUser = ref(db, `users/${authId}`);
  const formData = new FormData();
  formData.append('orderId', String(sub.orderId));
  formData.append('userId', String(userId));

  try {
    const req = await fetch('/scripts/order/get_payment.php', {
      method: 'POST',
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
      mode: 'no-cors',
    });
    //console.log(req);
    const res = await req.text();
    console.log(res);
    //console.log(JSON.stringify(res));
    const jsonStr = JSON.stringify(res);
    const regPaid = jsonStr.match(/\[paid.{1,5}1/ims);
    //console.log(regPaid);

    if (req.status === 200 && !!regPaid) {
      dispatch(success());
      const newOrder = {
        paid: true,
      };
      const newSub = {
        subscription: { ...sub, pending: false, paid: true },
      };

      update(dbRefOrder, newOrder)
        .then(() => {
          //
        })
        .catch(err => {
          console.log(err);
          dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
        });
      update(dbRefUser, newSub)
        .then(() => {
          //
        })
        .catch(err => {
          console.log(err);
          dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
        });
    }

    if (req.status === 202) {
      dispatch(pending());
    }

    if (req.status === 204) {
      const newSub = {
        subscription: { ...sub, date: '', deadline: '', pending: false, paid: false },
      };
      update(dbRefUser, newSub)
        .then(() => {
          //
        })
        .catch(err => {
          console.log(err);
          dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
        });
      //
      dispatch(failure('Ошибка оплаты заказа'));
    }

    if (req.status === 500 || req.status === 501) {
      dispatch(
        failure(
          `Ошибка проверки заказа, обратитесь в службу поддержки. Номер заказа: ${sub.orderId}`,
        ),
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(failure('Ошибка: ' + err));
  }
};

export const clearOrder = () => async (dispatch: AppDispatch) => {
  dispatch(clearPayment());
};

export const clearOrders = () => async (dispatch: AppDispatch) => {
  dispatch(clear());
};
