import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { getUser } from '@/store/profile/selectors';
import { feedbackPending, feedbackLoaded, feedbackFailure } from '@/store/feedback/selectors';
import { sendFeedbackData, sendSupportMsg } from '@/store/feedback/slicer';
import { PropsModal } from './types';
import { checkReg, checkRegMsg, formatVisitDate } from '@/utils/methods';
import { modalStyle } from '@/utils/constants';

const FeedbackModal: React.FC<PropsModal> = React.memo((props: PropsModal) => {
  const dispatch = useDispatch();
  const { currUser } = useSelector(getUser, shallowEqual);
  const loading = useSelector(feedbackPending, shallowEqual);
  const loaded = useSelector(feedbackLoaded, shallowEqual);
  const feedbackError = useSelector(feedbackFailure, shallowEqual);
  const [select, setSelect] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [dialogError, setDialogError] = useState('');

  const { settings } = props;
  const { openModal, setOpenModal } = settings;

  //console.log(mode);

  useEffect(() => {
    if (openModal && currUser?.id) {
      setEmail(currUser?.email || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const handleClose = () => {
    setOpenModal(false);
    setSelect('');
    setEmail('');
    setText('');
    setDialogError('');
  };

  useEffect(() => {
    if (!!loaded) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!!feedbackError) setDialogError(feedbackError);
  }, [feedbackError]);

  // const changeThanks = (e: ChangeEvent) => {
  //   if (e.target.value.length <= 60) setSelect(e.target.value);
  //   setDialogError('');
  // };

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 50) setEmail(e.target.value);
  };

  const changeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= 500) setText(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const regs = checkReg([email, text]);
    const regsMsg = checkRegMsg(text);
    const formData = new FormData();

    if (!!regs || !!regsMsg.hack) {
      setDialogError('Введите необходимые данные корректно');
      return;
    } else setDialogError('');

    if (!currUser?.id && !regs) {
      formData.append('title', '[Заявка на обратную связь]');
      formData.append('theme', select);
      formData.append('email', email);
      formData.append('text', text);

      dispatch(sendFeedbackData(formData));
      //console.log(formData);
    }

    if (!!currUser?.id && !regs) {
      const obj = {
        //id: 1,
        theme: select,
        date: formatVisitDate(),
        text: text,
        user: currUser,
        done: false,
      };

      dispatch(sendSupportMsg(obj));
    }
  };

  return (
    <Dialog open={openModal} onClose={handleClose} PaperProps={{ sx: modalStyle }}>
      <div className="mainDialog feedbackDialog">
        <button className="btnClose">
          <img src="/images/icons/close.svg" alt="close" onClick={handleClose} />
        </button>
        <DialogTitle className="heading">Ваше обращение</DialogTitle>

        <DialogContent className="content">
          <form id="feedback-form" method="POST" className="dialogForm" onSubmit={handleSubmit}>
            <select
              className="field select"
              //id=""
              //placeholder=""
              name="gender"
              required
              value={select}
              onChange={e => setSelect(e.target.value)}
            >
              <option value="">--Выберите тему обращения--</option>
              <option value="Благодарность">Благодарность нашей команде</option>
              <option value="Жалоба">Жалоба</option>
              <option value="Предложение">Предложение</option>
              <option value="Вопрос">Вопрос</option>
              <option value="Другое">Другое</option>
            </select>
            <input
              className="field"
              type="email"
              name="email"
              placeholder="E-mail"
              required
              autoComplete="on"
              value={email}
              onChange={changeEmail}
              pattern="(.{1,})@(.{1,}).([A-z]{2,8})"
              maxLength={50}
            />
            <textarea
              className="textarea"
              name="message"
              id="message"
              placeholder="Описание"
              required
              rows={5}
              maxLength={500}
              spellCheck="true"
              value={text}
              onChange={changeText}
            />

            <div className="btnsWrap">
              <button className="btnSubmit" type="submit">
                Отправить
              </button>
              <button className="btnSubmit" type="button" onClick={handleClose}>
                Отмена
              </button>
            </div>
          </form>
        </DialogContent>

        {!!dialogError && <p className="dialogError">{dialogError}</p>}
        {loading && (
          <div className="dialogLoading">
            <CircularProgress className="progress" />
          </div>
        )}
      </div>
    </Dialog>
  );
});

export default FeedbackModal;
