/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { onValue, ref, set, remove } from 'firebase/database';
import { db } from '../../services/firebase';
import { AppDispatch } from '@store/index';
import { photosUrl } from '@/utils/constants';
import { getCodeDb } from '@/utils/methods';
//import { Photo } from '@/scenes/Profile/types';

const initialState = {
  photos: [],
  viewedPhotos: [],
  request: {
    status: 0,
    error: null,
  },
  requestViewed: {
    status: 0,
    error: null,
  },
};

const photosSlice = createSlice({
  name: 'photos',
  initialState,
  reducers: {
    setPhotos: (state, { payload }) => {
      state.photos = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    pending: state => {
      state.request.status = 1;
    },
    success: state => {
      state.request.status = 2;
    },
    failure: (state, { payload }) => {
      state.request.status = 3;
      state.request.error = payload;
    },
    setViewedPhotos: (state, { payload }) => {
      state.viewedPhotos = payload;
      state.requestViewed.status = 2;
      state.requestViewed.error = null;
    },
    pendingViewed: state => {
      state.requestViewed.status = 1;
    },
    successViewed: state => {
      state.requestViewed.status = 2;
    },
    failureViewed: (state, { payload }) => {
      state.requestViewed.status = 3;
      state.requestViewed.error = payload;
    },
    clearViewed: state => {
      state.viewedPhotos = [];
      state.requestViewed.status = 0;
      state.requestViewed.error = null;
    },
    clear: state => {
      state.photos = [];
      state.viewedPhotos = [];
      state.request.status = 0;
      state.request.error = null;
      state.requestViewed.status = 0;
      state.requestViewed.error = null;
    },
  },
});

const {
  pending,
  failure,
  setPhotos,
  pendingViewed,
  failureViewed,
  setViewedPhotos,
  clearViewed,
  clear,
} = photosSlice.actions;
export default photosSlice.reducer;

export const initPhotosDb = (userId: number) => (dispatch: AppDispatch) => {
  dispatch(pending());
  //const id = auth.currentUser.uid;
  const dbRef = ref(db, `photos/${userId}`);

  onValue(
    dbRef,
    snapshot => {
      const data = snapshot.val();
      dispatch(setPhotos(Object.values(data || {})));
    },
    err => {
      console.log(err);
      dispatch(failure('Ошибка: ' + getCodeDb(err.name)));
    },
  );
};

export const sendPhotoDb =
  (data: FormData, photoId: string, userId: number) => async (dispatch: AppDispatch) => {
    dispatch(pending());
    const dbRef = ref(db, `photos/${userId}/${photoId}`);

    try {
      const res = await fetch('/scripts/photo/upload_photo.php', {
        method: 'POST',
        //headers: { 'Content-Type': 'multipart/form-data' },
        body: data,
      });
      //console.log(res);

      if (res.status === 200) {
        //dispatch(success());
        const obj = {
          id: photoId,
          url: photosUrl + photoId,
          thumb: photosUrl + photoId + '_thumb',
        };

        set(dbRef, obj)
          .then(() => {
            //
          })
          .catch(err => {
            console.log(err);
            dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
          });
        //
      } else dispatch(failure('Фото не загрузилось. Проверьте тип и размер файла.'));
    } catch (err) {
      console.log(err);
      dispatch(failure('Ошибка: ' + err));
    }
  };

export const deletePhotoDb =
  (photoId: string, data: FormData, userId: number) => async (dispatch: AppDispatch) => {
    dispatch(pending());

    const res = await fetch('/scripts/photo/unlink_photo.php', {
      method: 'POST',
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: data,
    });
    //console.log(res);

    remove(ref(db, `photos/${userId}/${photoId}`))
      .then(() => {
        //
      })
      .catch(err => {
        console.log(err);
        dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
      });
  };

export const getViewedPhotos = (userId: number) => (dispatch: AppDispatch) => {
  dispatch(pendingViewed());

  const dbRef = ref(db, `photos/${userId}`);
  onValue(
    dbRef,
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      dispatch(setViewedPhotos(data));
    },
    err => {
      console.log(err);
      dispatch(failureViewed('Ошибка: ' + getCodeDb(err.name)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const clearViewedPhotos = () => async (dispatch: AppDispatch) => {
  dispatch(clearViewed());
};

export const clearPhotos = () => async (dispatch: AppDispatch) => {
  dispatch(clear());
};
