import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '@/components/Sidebar';

interface Props {
  children: ReactNode;
}

const Scene: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();

  const menuPath =
    pathname === '/profile' ||
    pathname === '/questionnaires' ||
    pathname === '/messages' ||
    pathname === '/sympathies' ||
    pathname === '/settings';

  if (!menuPath) return <main>{children}</main>;
  if (menuPath)
    return (
      <main>
        <div className="mainPage container">
          <Sidebar />
          {children}
        </div>
      </main>
    );
};

export default Scene;
