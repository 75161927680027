/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { onValue, ref, set, remove } from 'firebase/database';
import { db } from '../../services/firebase';
import { AppDispatch } from '@store/index';
import { User } from '@/utils/mainTypes';
import { getCodeDb } from '@/utils/methods';

const initialState = {
  likedUsers: [],
  matchedUsers: [],
  guestedUsers: [],
  closedUsers: [],
  cityList: [],
  request: {
    status: 0,
    error: null,
  },
  requestMatch: {
    status: 0,
    error: null,
  },
  requestData: {
    status: 0,
    error: null,
  },
};

const sympathiesSlice = createSlice({
  name: 'sympathies',
  initialState,
  reducers: {
    setLikeds: (state, { payload }) => {
      state.likedUsers = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    setMatcheds: (state, { payload }) => {
      state.matchedUsers = payload;
      state.requestMatch.status = 4;
      state.request.error = null;
    },
    matchedSuccess: state => {
      state.requestMatch.status = 4;
    },
    setGuesteds: (state, { payload }) => {
      state.guestedUsers = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    setCloseds: (state, { payload }) => {
      state.closedUsers = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    setCities: (state, { payload }) => {
      state.cityList = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    pending: state => {
      state.request.status = 1;
      state.requestMatch.status = 1;
    },
    success: state => {
      state.request.status = 2;
    },
    failure: (state, { payload }) => {
      state.request.status = 3;
      state.request.error = payload;
    },
    pendingData: state => {
      state.requestData.status = 1;
    },
    failureData: (state, { payload }) => {
      state.requestData.status = 3;
      state.requestData.error = payload;
    },
    clear: state => {
      state.likedUsers = [];
      state.matchedUsers = [];
      state.guestedUsers = [];
      state.closedUsers = [];
      state.cityList = [];
      state.request.status = 0;
      state.request.error = null;
      state.requestMatch.status = 0;
      state.requestData.status = 0;
      state.requestData.error = null;
    },
  },
});

const {
  pending,
  success,
  failure,
  pendingData,
  failureData,
  setLikeds,
  setMatcheds,
  //matchedSuccess,
  setGuesteds,
  setCloseds,
  setCities,
  clear,
} = sympathiesSlice.actions;
export default sympathiesSlice.reducer;

export const getLikedMatchedUsersDb = (id: string) => (dispatch: AppDispatch) => {
  dispatch(pending());

  const dbRefLiked = ref(db, `likedUsers/${id}`);
  const dbRefMatched = ref(db, `matchedUsers/${id}`);

  onValue(
    dbRefLiked,
    snapshot => {
      const data = snapshot.val();
      dispatch(setLikeds(Object.values(data || {})));
    },
    err => {
      console.log(err);
      dispatch(failure('Ошибка: ' + getCodeDb(err.name)));
    },
  );

  onValue(
    dbRefMatched,
    snapshot => {
      const data = snapshot.val();
      dispatch(setMatcheds(Object.values(data || {})));
    },
    err => {
      console.log(err);
      dispatch(failure('Ошибка: ' + getCodeDb(err.name)));
    },
  );
};

export const getGuestedUsersDb = (id: string) => (dispatch: AppDispatch) => {
  dispatch(pending());

  //const authId = auth.currentUser.uid;
  const dbRef = ref(db, `guestedUsers/${id}`);
  onValue(
    dbRef,
    snapshot => {
      const data = snapshot.val();
      dispatch(setGuesteds(Object.values(data || {})));
    },
    err => {
      console.log(err);
      dispatch(failure('Ошибка: ' + getCodeDb(err.name)));
    },
  );
};

export const setLikedUserDb = (user: User, currUser: User) => (dispatch: AppDispatch) => {
  dispatch(pending());

  const dbRefLiked = ref(db, `likedUsers/${currUser.id}/${user.id}`);
  const dbRefMyMatched = ref(db, `matchedUsers/${currUser.id}/${user.id}`);
  const dbReMyGuested = ref(db, `guestedUsers/${currUser.id}/${user.id}`);
  const dbRefHisMatched = ref(db, `matchedUsers/${user.id}/${currUser.id}`);
  const dbRefHisGuested = ref(db, `guestedUsers/${user.id}/${currUser.id}`);
  //const closed = true;

  onValue(
    ref(db, `closedUsers/${user.id}`),
    snapshot => {
      const resData = Object.values(snapshot.val() || {});
      //console.log(data);
      const userClosed = resData.find(({ id }) => id === currUser.id);

      if (!!userClosed) {
        //closed = true;
        dispatch(success());
      } else {
        onValue(
          ref(db, `likedUsers/${user.id}`),
          newSnapshot => {
            const data = Object.values(newSnapshot.val() || {});
            //console.log(data);
            const userLiked = data.find(({ id }) => id === currUser.id);

            if (!!userLiked) {
              const newUser = { ...user, matched: true };
              const newCurrUser = { ...currUser, matched: true };
              //dispatch(matchedSuccess());
              set(dbRefMyMatched, user)
                .then(() => {
                  //
                })
                .catch(err => {
                  console.log(err);
                  dispatch(failure(getCodeDb(err.code)));
                });

              set(dbRefHisMatched, currUser)
                .then(() => {
                  //
                })
                .catch(err => {
                  console.log(err);
                  dispatch(failure(getCodeDb(err.code)));
                });

              set(dbReMyGuested, newUser)
                .then(() => {
                  //
                })
                .catch(err => {
                  console.log(err);
                  dispatch(failure(getCodeDb(err.code)));
                });

              set(dbRefHisGuested, newCurrUser)
                .then(() => {
                  //
                })
                .catch(err => {
                  console.log(err);
                  dispatch(failure(getCodeDb(err.code)));
                });
            } else {
              set(dbRefHisGuested, currUser)
                .then(() => {
                  //
                })
                .catch(err => {
                  console.log(err);
                  dispatch(failure(getCodeDb(err.code)));
                });
              //dispatch(success());
            }

            set(dbRefLiked, user)
              .then(() => {
                //
              })
              .catch(err => {
                console.log(err);
                dispatch(failure(getCodeDb(err.code)));
              });
          },
          err => {
            console.log(err);
            dispatch(failure('Ошибка: ' + getCodeDb(err.name)));
          },
          {
            onlyOnce: true,
          },
        );
      }
    },
    err => {
      console.log(err);
      dispatch(failure('Ошибка: ' + getCodeDb(err.name)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const getClosedUsersDb = (id: string) => (dispatch: AppDispatch) => {
  dispatch(pending());

  //const id = auth.currentUser.uid;
  const dbRef = ref(db, `closedUsers/${id}`);
  onValue(
    dbRef,
    snapshot => {
      const data = snapshot.val();
      dispatch(setCloseds(Object.values(data || {})));
    },
    err => {
      console.log(err);
      dispatch(failure('Ошибка: ' + getCodeDb(err.name)));
    },
  );
};

export const setClosedUserDb = (user: User, currUser: User) => (dispatch: AppDispatch) => {
  dispatch(pending());

  const dbRefMy = ref(db, `closedUsers/${currUser.id}/${user.id}`);
  const dbRefHis = ref(db, `closedUsers/${user.id}/${currUser.id}`);

  set(dbRefMy, user)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(failure(getCodeDb(err.code)));
    });

  set(dbRefHis, currUser)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(failure(getCodeDb(err.code)));
    });
};

export const setClosedSympathyDb = (user: User, currUser: User) => (dispatch: AppDispatch) => {
  dispatch(pending());

  const dbRef = ref(db, `closedUsers/${currUser.id}/${user.id}`);

  remove(ref(db, `matchedUsers/${currUser.id}/${user.id}`))
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(failure(getCodeDb(err.code)));
    });
  remove(ref(db, `likedUsers/${currUser.id}/${user.id}`))
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(failure(getCodeDb(err.code)));
    });
  remove(ref(db, `guestedUsers/${currUser.id}/${user.id}`))
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(failure(getCodeDb(err.code)));
    });

  remove(ref(db, `matchedUsers/${user.id}/${currUser.id}`))
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(failure(getCodeDb(err.code)));
    });
  remove(ref(db, `guestedUsers/${user.id}/${currUser.id}`))
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(failure(getCodeDb(err.code)));
    });

  set(dbRef, user)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(failure(getCodeDb(err.code)));
    });
};

// export const setClosedLikeDb = (user: User, currUser: User) => (dispatch: AppDispatch) => {
//   dispatch(pending());

//   const dbRef = ref(db, `closedUsers/${currUser.id}/${user.id}`);

//   remove(ref(db, `matchedUsers/${currUser.id}/${user.id}`))
//     .then(() => {
//       //
//     })
//     .catch(err => {
//       console.log(err);
//       dispatch(failure(err.message));
//     });
//   remove(ref(db, `likedUsers/${currUser.id}/${user.id}`))
//     .then(() => {
//       //
//     })
//     .catch(err => {
//       console.log(err);
//       dispatch(failure(err.message));
//     });

//   remove(ref(db, `matchedUsers/${user.id}/${currUser.id}`))
//     .then(() => {
//       //
//     })
//     .catch(err => {
//       console.log(err);
//       dispatch(failure(err.message));
//     });
//   remove(ref(db, `guestedUsers/${user.id}/${currUser.id}`))
//     .then(() => {
//       //
//     })
//     .catch(err => {
//       console.log(err);
//       dispatch(failure(err.message));
//     });

//   set(dbRef, user)
//     .then(() => {
//       //
//     })
//     .catch(err => {
//       console.log(err);
//       dispatch(failure(err.message));
//     });
// };

// export const setClosedGuestDb = (user: User, currUser: User) => (dispatch: AppDispatch) => {
//   dispatch(pending());

//   const dbRef = ref(db, `closedUsers/${currUser.id}/${user.id}`);

//   remove(ref(db, `guestedUsers/${currUser.id}/${user.id}`))
//     .then(() => {
//       //
//     })
//     .catch(err => {
//       console.log(err);
//       dispatch(failure(err.message));
//     });

//   set(dbRef, user)
//     .then(() => {
//       //
//     })
//     .catch(err => {
//       console.log(err);
//       dispatch(failure(err.message));
//     });
// };

export const getCities = () => async (dispatch: AppDispatch) => {
  dispatch(pendingData());
  //const d = +Date.now().toString().slice(3, -2); //?nocache=123
  try {
    const res = await fetch('/scripts/russian-cities.json');
    const data = await res.json();
    //console.log(data);
    if (!!res.ok) dispatch(setCities(data.cities));
    else console.log(res.status);
  } catch (err) {
    console.log(err);
    dispatch(failureData(err));
  }
};

export const clearSympathies = () => async (dispatch: AppDispatch) => {
  dispatch(clear());
};
