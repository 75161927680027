import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const Home = React.lazy(() => import(/* webpackChunkName: "home" */ '@/scenes/Home'));
const Profile = React.lazy(() => import(/* webpackChunkName: "profile" */ '@/scenes/Profile'));
const Questionnaires = React.lazy(
  () => import(/* webpackChunkName: "profile" */ '@/scenes/Questionnaires'),
);
const Messages = React.lazy(() => import(/* webpackChunkName: "profile" */ '@/scenes/Messages'));
const Sympathies = React.lazy(
  () => import(/* webpackChunkName: "profile" */ '@/scenes/Sympathies'),
);
const Settings = React.lazy(() => import(/* webpackChunkName: "profile" */ '@/scenes/Settings'));

const Help = React.lazy(() => import(/* webpackChunkName: "extra" */ '@/scenes/ExtraPages/Help'));

const Contacts = React.lazy(
  () => import(/* webpackChunkName: "extra" */ '@/scenes/ExtraPages/Contacts'),
);
const Policy = React.lazy(
  () => import(/* webpackChunkName: "extra" */ '@/scenes/ExtraPages/Policy'),
);
const Rules = React.lazy(() => import(/* webpackChunkName: "extra" */ '@/scenes/ExtraPages/Rules'));
const Safety = React.lazy(
  () => import(/* webpackChunkName: "extra" */ '@/scenes/ExtraPages/Safety'),
);
const UserPolicy = React.lazy(
  () => import(/* webpackChunkName: "extra" */ '@/scenes/ExtraPages/UserPolicy'),
);
const NotFound = React.lazy(() => import(/* webpackChunkName: "notfound" */ '@/scenes/NotFound'));

const Login = React.lazy(() => import(/* webpackChunkName: "admin" */ '@/scenes/AdminPanel/Login'));
const Lk = React.lazy(() => import(/* webpackChunkName: "admin" */ '@/scenes/AdminPanel/Lk'));

const Router: React.FC = () => {
  return (
    <Suspense
      fallback={
        <CircularProgress
          color="primary"
          sx={{
            position: 'absolute',
            top: '20%',
            left: '49%',
            color: '#6F607D',
          }}
        />
      }
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/questionnaires" element={<Questionnaires />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/sympathies" element={<Sympathies />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/user-policy" element={<UserPolicy />} />
        <Route path="/help" element={<Help />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/safety" element={<Safety />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/lk" element={<Lk />} />
        {/* <Route path="/admin/:login" element={<Services />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
