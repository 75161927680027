/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { onValue, ref, set, update, remove } from 'firebase/database';
import { db } from '../../services/firebase';
import { AppDispatch } from '@store/index';
import { Chat, Message, User } from '@/utils/mainTypes';
import { getCodeDb } from '@/utils/methods';

const initialState = {
  chatsList: [],
  //messagesList: [],
  messagesList: {},
  bannedUser: null,
  deletedUser: null,
  requestChats: {
    status: 0,
    error: null,
  },
  requestMessages: {
    status: 0,
    error: null,
  },
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setChats: (state, { payload }) => {
      state.chatsList = payload;
      state.requestChats.status = 2;
      state.requestChats.error = null;
    },
    // setMessages: (state, { payload }) => {
    //   state.messagesList = payload;
    //   state.requestMessages.status = 2;
    //   state.requestMessages.error = null;
    // },
    setMessages: (state, { payload }) => {
      state.messagesList[payload.id] = payload.list;
      state.requestMessages.status = 2;
      state.requestMessages.error = null;
    },
    setBanUser: (state, { payload }) => {
      state.bannedUser = payload;
      state.requestMessages.status = 6;
      state.requestMessages.error = null;
    },
    setDeletedUser: (state, { payload }) => {
      state.deletedUser = payload;
      // state.requestMessages.status = 6;
      // state.requestMessages.error = null;
    },
    chatsPending: state => {
      state.requestChats.status = 1;
      //state.requestMatch.status = 1;
    },
    chatsSuccess: state => {
      state.requestChats.status = 2;
    },
    chatsFailure: (state, { payload }) => {
      state.requestChats.status = 3;
      state.requestChats.error = payload;
    },
    messagesPending: state => {
      state.requestMessages.status = 1;
      //state.requestMatch.status = 1;
    },
    messagesSuccess: state => {
      state.requestMessages.status = 2;
    },
    messagesFailure: (state, { payload }) => {
      state.requestMessages.status = 3;
      state.requestMessages.error = payload;
    },
    banMsgPending: state => {
      state.requestMessages.status = 5;
    },
    clearMsg: state => {
      state.messagesList = [];
      state.requestMessages.status = 0;
      state.requestMessages.error = null;
    },
    clear: state => {
      state.chatsList = [];
      state.messagesList = {};
      state.bannedUser = null;
      state.deletedUser = null;
      state.requestChats.status = 0;
      state.requestChats.error = null;
      state.requestMessages.status = 0;
      state.requestMessages.error = null;
    },
  },
});

const {
  chatsPending,
  //chatsSuccess,
  chatsFailure,
  messagesPending,
  messagesSuccess,
  messagesFailure,
  banMsgPending,
  setChats,
  setMessages,
  setBanUser,
  setDeletedUser,
  //setAllMessages,
  clearMsg,
  clear,
} = messagesSlice.actions;
export default messagesSlice.reducer;

export const getChatsDb = (id: string) => (dispatch: AppDispatch) => {
  dispatch(chatsPending());

  //const authId = auth.currentUser.uid;
  const dbRef = ref(db, `chats/${id}`);
  onValue(
    dbRef,
    snapshot => {
      const data = snapshot.val();
      dispatch(setChats(Object.values(data || {})));
    },
    err => {
      console.log(err);
      dispatch(chatsFailure('Ошибка: ' + getCodeDb(err.name)));
    },
  );
};

export const getMessagesDb = (id: string) => (dispatch: AppDispatch) => {
  dispatch(messagesPending());

  //const authId = auth.currentUser.uid;
  const dbRef = ref(db, `messages/${id}`);
  onValue(
    dbRef,
    snapshot => {
      const data = snapshot.val();
      dispatch(setMessages(Object.values(data || {})));
    },
    err => {
      console.log(err);
      dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
    },
  );
};

export const getAllMessagesDb = (chats: Array<Chat>) => (dispatch: AppDispatch) => {
  dispatch(messagesPending());

  //const list = {};
  chats.forEach((chat: Chat) => {
    const dbRef = ref(db, `messages/${chat.id}`);
    onValue(
      dbRef,
      snapshot => {
        const data = snapshot.val();
        dispatch(
          setMessages({
            id: chat.id,
            list: Object.values(data || {}),
          }),
        );
      },
      err => {
        console.log(err);
        dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
      },
      // {
      //   onlyOnce: true,
      // },
    );
  });
  //dispatch(setAllMessages(list));
};

export const getBanSendMsg = (userId: string, myId: string) => (dispatch: AppDispatch) => {
  dispatch(banMsgPending());

  const dbRef = ref(db, `closedUsers/${userId}`);
  onValue(
    dbRef,
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      const user = data.find(({ id }) => id === myId);
      if (!!user) {
        dispatch(setBanUser(user));
      } else dispatch(setBanUser(null));
    },
    err => {
      console.log(err);
      dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const addChatDb =
  (chatId: string, user: User, currUser: User, msg: Message, timeZone: string, adminId: number) => (dispatch: AppDispatch) => {
    dispatch(chatsPending());

    //const authId = auth.currentUser.uid;
    const dbRefChats = ref(db, `chats/${user.id}`);
    const dbRefMyChat = ref(db, `chats/${currUser.id}/${chatId}`);
    const dbRefHisChat = ref(db, `chats/${user.id}/${chatId}`);

    onValue(
      dbRefChats,
      snapshot => {
        const data: Array<Chat> = Object.values(snapshot.val() || {});
        const chat = data.find(({ userId }) => userId === +currUser.id) as Chat;
        //console.log(chat);
        if (!!chat) {
          let obj = null;
          obj = {
            id: chat.id,
            userId: user.id,
            name: `${user.personal.name}, ${user.personal.age}`,
            avatar: user.avatar,
            lastMsg: msg.text,
            lastReadMsg: { id: 'msg-0' },
            read: false,
            favorite: false,
            timeZone: timeZone,
            userVerified: user?.verified === 'success' ? true : false,
          };
          if (!!adminId) {
            obj.support = true;
          }

          set(ref(db, `chats/${currUser.id}/${chat.id}`), obj)
            .then(() => {
              //
            })
            .catch(err => {
              console.log(err);
              dispatch(chatsFailure('Ошибка: ' + getCodeDb(err.code)));
            });

          setTimeout(() => {
            dispatch(addMessageDb(chat.id, +user.id, currUser, msg));
          }, 0);
        } else {
          let myChat = null;
          myChat = {
            id: chatId,
            userId: user.id,
            name: `${user.personal.name}, ${user.personal.age}`,
            avatar: user.avatar,
            lastMsg: msg.text,
            read: true,
            favorite: false,
            timeZone: timeZone,
            userVerified: user?.verified === 'success' ? true : false,
          };

          let hisChat = null;
          hisChat = {
            id: chatId,
            userId: currUser.id,
            name: `${currUser.personal.name}, ${currUser.personal.age}`,
            avatar: currUser.avatar,
            lastMsg: '',
            lastReadMsg: { id: 'msg-0' },
            read: false,
            favorite: false,
            timeZone: currUser.timeZone,
            userVerified: currUser?.verified === 'success' ? true : false,
          };

          if (!!adminId) {
            myChat.support = true;

            hisChat.name = 'Служба поддержки (' + currUser.personal.name + ')';
            hisChat.favorite = true;
            hisChat.support = true;
          }

          set(dbRefMyChat, myChat)
            .then(() => {
              //
            })
            .catch(err => {
              console.log(err);
              dispatch(chatsFailure('Ошибка: ' + getCodeDb(err.code)));
            });
          set(dbRefHisChat, hisChat)
            .then(() => {
              //
            })
            .catch(err => {
              console.log(err);
              dispatch(chatsFailure('Ошибка: ' + getCodeDb(err.code)));
            });

          setTimeout(() => {
            dispatch(addMessageDb(chatId, +user.id, currUser, msg));
          }, 0);
        }
      },
      err => {
        console.log(err);
        dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
      },
      {
        onlyOnce: true,
      },
    );
  };

export const updateChatDb =
  (userId: number, chatId: string, data: object) => (dispatch: AppDispatch) => {
    //dispatch(chatsPending());

    const dbRef = ref(db, `chats/${userId}/${chatId}`);
    update(dbRef, data)
      .then(() => {
        //dispatch(chatsSuccess());
        //console.log(data);
      })
      .catch(err => {
        console.log(err);
        dispatch(chatsFailure('Ошибка: ' + getCodeDb(err.name)));
      });
  };

export const deleteChatDb =
  (userId: number, hisId: number, chatId: string) => (dispatch: AppDispatch) => {
    dispatch(chatsPending());

    const dbRefMsg = ref(db, `messages/${chatId}`);
    const dbRefHisChat = ref(db, `chats/${hisId}/${chatId}`);

    remove(ref(db, `chats/${userId}/${chatId}`))
      .then(() => {
        //
      })
      .catch(err => {
        console.log(err);
        dispatch(chatsFailure('Ошибка: ' + getCodeDb(err.name)));
      });

    onValue(
      dbRefHisChat,
      snapshot => {
        const data = snapshot.val();
        //console.log(data);

        if (!data) {
          //dispatch(matchedSuccess());
          remove(dbRefMsg)
            .then(() => {
              //
            })
            .catch(err => {
              console.log(err);
              dispatch(chatsFailure('Ошибка: ' + getCodeDb(err.name)));
            });
        }
      },
      err => {
        console.log(err);
        dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
      },
      {
        onlyOnce: true,
      },
    );
  };

export const addMessageDb =
  (chatId: string, hisId: number, currUser: User, message: Message) => (dispatch: AppDispatch) => {
    //dispatch(messagesPending());

    const dbRefMsg = ref(db, `messages/${chatId}/${message.id}`);
    //const dbRefMyChat = ref(db, `chats/${currUser.id}/${chatId}`);
    const dbRefHisChat = ref(db, `chats/${hisId}/${chatId}`);

    // const updatedMyChat = {
    //   lastMsg: message.text,
    //   //lastReadMsg: message.id,
    // };
    const updatedChat = {
      read: false,
      lastMsg: message.text,
    };
    const hisChat = {
      id: chatId,
      userId: currUser.id,
      name: `${currUser.personal.name}, ${currUser.personal.age}`,
      avatar: currUser.avatar,
      lastMsg: message.text,
      lastReadMsg: { id: 'msg-0' },
      read: false,
      favorite: false,
      timeZone: currUser.timeZone,
      userVerified: currUser?.verified === 'success' ? true : false,
    };

    // update(dbRefMyChat, updatedMyChat)
    //   .then(() => {
    //     //
    //   })
    //   .catch(err => {
    //     console.log(err);
    //     dispatch(messagesFailure(err.message));
    //   });

    function sendMsg() {
      set(dbRefMsg, message)
        .then(() => {
          dispatch(messagesSuccess());
        })
        .catch(err => {
          console.log(err);
          dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
        });
    }

    onValue(
      dbRefHisChat,
      snapshot => {
        const data = Object.values(snapshot.val() || {});
        //console.log(data);
        //const filteredData = data.find(({ id }) => id === currUser.id);

        if (!data.length) {
          //dispatch(matchedSuccess());
          set(dbRefHisChat, hisChat)
            .then(() => {
              //
            })
            .catch(err => {
              console.log(err);
              dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
            });
          sendMsg();
        } else {
          update(dbRefHisChat, updatedChat)
            .then(() => {
              //
            })
            .catch(err => {
              console.log(err);
              dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
            });
          sendMsg();
        }
      },
      err => {
        console.log(err);
        dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
      },
      {
        onlyOnce: true,
      },
    );
  };

export const deleteMessageDb = (chatId: string, msgId: string) => (dispatch: AppDispatch) => {
  dispatch(messagesPending());

  const dbRefMsg = ref(db, `messages/${chatId}/${msgId}`);
  remove(dbRefMsg)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
    });
};

export const updateMessageDb =
  (chatId: string, msgId: string, data: object) => (dispatch: AppDispatch) => {
    dispatch(messagesPending());

    const dbRefMsg = ref(db, `messages/${chatId}/${msgId}`);
    update(dbRefMsg, data)
      .then(() => {
        //
      })
      .catch(err => {
        console.log(err);
        dispatch(messagesFailure('Ошибка: ' + getCodeDb(err.name)));
      });
  };

export const getDeletedUser = (userId: number) => (dispatch: AppDispatch) => {
  //dispatch(pending());

  onValue(
    ref(db, 'users'),
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      const userDb = data.find(({ id }) => id === userId);
      if (!!userDb) dispatch(setDeletedUser(null));
      else dispatch(setDeletedUser(userId));
    },
    err => {
      console.log(err);
      //dispatch(failure('Ошибка: ' + err.message.slice(16)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const clearMsgList = () => (dispatch: AppDispatch) => {
  dispatch(clearMsg());
};

export const clearMessages = () => (dispatch: AppDispatch) => {
  dispatch(clear());
};

// export const deleteMessagesDb = () => (dispatch: AppDispatch) => {
//   if (!!auth) dispatch(clear());
// };
