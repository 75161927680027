//
export const photosUrl = 'https://oneclickmate.ru/uploads/';

export const modalStyle = {
  m: '10px',
  maxWidth: 'initial',
  background: '#FFF',
  maxHeight: 'calc(100% - 20px)',
};

// 'Главный конкурент всех сайтов знакомств. Сервис поиска настоящих людей и отношений'

export const menuLinks = [
  {
    name: 'Главная',
    path: '/',
    title: 'OneClickMate — Real life in one click',
    description:
      'Сервис для поиска серьезных отношений. Главный конкурент всех сайтов знакомств. Сайт, где можно найти настоящих людей и вторую половинку',
  },
  {
    name: 'Профиль',
    path: '/profile',
    title: 'Профиль | OneClickMate',
    description:
      'В разделе Профиль можно добавлять и изменять различные данные: основную информацию, интересы, раздел о себе, добавлять фотографии',
  },
  {
    name: 'Анкеты',
    path: '/questionnaires',
    title: 'Анкеты | OneClickMate',
    description:
      'В разделе Анкеты можно выбрать нужную вам анкету, путем поиска подходящего партнера',
  },
  {
    name: 'Сообщения',
    path: '/messages',
    title: 'Сообщения | OneClickMate',
    description: 'В разделе Сообщения можно обмениваться сообщениями с подходящими вам партнерами',
  },
  {
    name: 'Симпатии',
    path: '/sympathies',
    title: 'Симпатии | OneClickMate',
    description:
      'В разделе Симпатии появляются выбранные вами партнеры или партнеры которые выбрали вас',
  },
  {
    name: 'Настройки',
    path: '/settings',
    title: 'Настройки | OneClickMate',
    description:
      'В разделе Настройки можно изменять данные вашего аккаунта, а также пройти верификацию для подтверждения вашего профиля',
  },
];

export const timeZoneList = [
  {
    name: 'МСК-1',
    utc: 'UTC+2',
    //time: '',
    value: 'Europe/Kaliningrad',
  },
  {
    name: 'МСК',
    utc: 'UTC+3',
    value: 'Europe/Moscow', // 'Europe/Simferopol', 'Europe/Kirov', 'Europe/Volgograd'
  },
  {
    name: 'МСК+1',
    utc: 'UTC+4',
    value: 'Europe/Samara', // 'Europe/Astrakhan', 'Europe/Saratov', 'Europe/Ulyanovsk'
  },
  {
    name: 'МСК+2',
    utc: 'UTC+5',
    value: 'Asia/Yekaterinburg',
  },
  {
    name: 'МСК+3',
    utc: 'UTC+6',
    value: 'Asia/Omsk',
  },
  {
    name: 'МСК+4',
    utc: 'UTC+7',
    value: 'Asia/Krasnoyarsk', // 'Asia/Novosibirsk', 'Asia/Barnaul', 'Asia/Tomsk', 'Asia/Novokuznetsk', ''
  },
  {
    name: 'МСК+5',
    utc: 'UTC+8',
    value: 'Asia/Irkutsk',
  },
  {
    name: 'МСК+6',
    utc: 'UTC+9',
    value: 'Asia/Yakutsk', // 'Asia/Chita', 'Asia/Khandyga'
  },
  {
    name: 'МСК+7',
    utc: 'UTC+10',
    value: 'Asia/Vladivostok', // 'Asia/Ust-Nera'
  },
  {
    name: 'МСК+8',
    utc: 'UTC+11',
    value: 'Asia/Magadan', // 'Asia/Sakhalin' 'Asia/Srednekolymsk'
  },
  {
    name: 'МСК+9',
    utc: 'UTC+12',
    value: 'Asia/Kamchatka', // 'Asia/Anadyr'
  },
];

export const helpList = [
  {
    title: '1. Чем вы отличаетесь от других сайтов знакомств?',
    text: 'Главное отличие OneClickMate от других подобных сайтов заключается в индивидуальном подходе к каждому пользователю. Стать участником OneClickMate возможно только при наличии серьёзного намерения найти себе пару. Мы не просто создали еще один ресурс для знакомств, но и тщательно продумали, как мы можем реально помочь вам встретить нужного и желанного человека. Это сайт, который мы создавали с любовью, продумывая каждую мелочь для вашего приятного пребывания на нём.',
  },
  {
    title: '2. Почему OneClickMate для людей старше 21 года?',
    text: 'OneClickMate создан для серьёзных знакомств. Мы искренне считаем, что большинство людей младше 21 года не готовы к серьёзным долгосрочным отношениям. Зачастую люди и более старшего возраста не полностью осознают, какого человека они желают видеть рядом с собой. Но чем старше человек, тем более чёткие цели он ставит перед собой. Поэтому мы готовы вкладывать свои усилия, знания, время и помогать тем, кто уже достиг возраста полного совершеннолетия.',
  },
  {
    title: '3. Я могу зарегистрироваться на OneClickMate, если я состою в браке?',
    text: 'Наш сайт создан для одиноких людей, ищущих себе пару. Если вы состоите в браке, то OneClickMate не для вас. Если, находясь в отношениях, вы регистрируетесь на сайте OneClickMate , то при обнаружении данного обстоятельства, ваша анкета будет удалена без права на восстановление. Мы уважаем ваш выбор и надеемся на уважение наших условий, а также на уважительное отношение к участникам сайта, которые приходят к нам с надеждой встретить надёжного спутника.',
  },
  {
    title: '4. Какие письма будут приходить мне после регистрации на сайте?',
    text: 'С момента регистрации на сайте OneClickMate вам придёт только три письма: письмо о подтверждении регистрации, письмо, если вы забыли пароль, и письмо с уведомлением об удалении анкеты, когда вы решите это сделать.',
  },
  {
    title: '5. Какие варианты оплаты услуг сайта OneClickMate существуют?',
    text: 'На сайте OneClickMate существует единый тариф для общего доступа ко всем основным услугам. При единовременной оплате за 3, 6 месяцев предусмотрена скидка.',
  },
  {
    title: ' 6. Что мне доступно после оплаты?',
    text: 'На сайте OneClickMate нет скрытых платежей, поэтому после оплаты услуг вам доступен весь основной функционал: переписка, неограниченное количество отправленных и полученных сообщений, лайков, неограниченный просмотр анкет.',
  },
  {
    title: '7. Легко ли отменить оплату? ',
    text: 'Отменить оплату услуг сайта OneClickMate очень легко, перейдя в раздел Настройки — Оплата.',
  },
  {
    title: '8. Как я могу удалить свою анкету с сайта? ',
    text: 'Если вы решили, что наши услуги вам больше не требуются, то удалить анкету можно в один клик в разделе Настройки.',
  },
  {
    title: ' 9. Я смогу выбирать любой город? ',
    text: 'Да, на сайте OneClickMate доступен выбор любого города без ограничений.',
  },
  {
    title: '10. Почему фото не прошло модерацию?',
    text: 'Ваши фотографии могут не пройти проверку в том случае, если они не соответствуют правилам сайта OneClickMate. Прежде чем загружать свои фотографии, советуем вам внимательно ознакомиться с нашими требованиями.',
  },
  {
    title: ' 11. Как лучше заполнить профиль?',
    text: 'Если вы искренне желаете встретить близкого вам по интересам человека, то заполняя свой профиль, важно писать только о себе, своих интересах, увлечениях и ценностях. Напишите самое важное и интересное, то, что, по вашему мнению, характеризует вас лучше всего и отличает от других людей. Так ваши шансы на удачу возрастут.',
  },
  {
    title: '12. Почему мой профиль не виден другим участникам, когда я вношу в него изменения?',
    text: 'Модераторы сайта OneClickMate проверяют любые изменения, которые вы вносите в фотографии и самопрезентацию. У нас серьёзные требования к оформлению вашей анкеты, и мы не можем допустить, чтобы они нарушались. Как только внесённые вами изменения проходят проверку, ваша анкета становиться снова видна остальным участникам.',
  },
  {
    title: ' 13. Почему в сообщениях нельзя делиться ссылками?',
    text: 'Мы заботимся о вашей веб-безопасности, и поэтому делиться ссылками в чате OneClickMate нельзя. Кроме перехода на полезные ресурсы, ссылки могут перебрасывать вас на мошеннические сайты. Например, вы можете оказаться быть подписанными на какую-нибудь услугу, потеряв таким образом свои деньги. Также ссылки на неизвестные ресурсы могут ввести вас в заблуждение с целью получить конфиденциальную информацию или активировать вредоносную программу на вашем устройстве.',
  },
];
