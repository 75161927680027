export default class User {
  id: string;
  dbId: string;
  activated: boolean;
  avatar: string;
  email: string;
  subscription: object;
  personal: object;
  interests: object;
  aboutme: object;
  gender: string;
  searchGender: string;
  timeZone: string;
  verified: boolean;
  loggedIn?: boolean;
  static id: string;
  static email: string;
  static avatar: string;

  constructor(
    id: string,
    dbId: string,
    activated: boolean,
    email: string,
    avatar: string,
    subscription: object,
    personal: object,
    interests: object,
    aboutme: object,
    gender: string,
    searchGender: string,
    timeZone: string,
    verified: boolean,
    loggedIn?: boolean,
  ) {
    this.id = id;
    this.dbId = dbId;
    this.activated = activated;
    this.email = email;
    this.avatar = avatar;
    this.subscription = subscription;
    this.personal = personal;
    this.interests = interests;
    this.aboutme = aboutme;
    this.gender = gender;
    this.searchGender = searchGender;
    this.timeZone = timeZone;
    this.verified = verified;
    this.loggedIn = loggedIn;
  }
}
