import { Subscription } from './mainTypes';

const mskTimeZone = 'Europe/Moscow';

export const getElement = (elId: string) => {
  const element = document.querySelector(elId);
  return {
    el: element,
    top: element?.getBoundingClientRect().top,
    height: element?.offsetHeight,
  };
};

export function checkReg(arr: Array<string>) {
  const regAll = arr.filter(val => val.match(/^\s+$/));
  //console.log(regAll);
  if (!!regAll.length) return true;
}

export function checkRegMsg(msg: string) {
  const obj = {
    str: null,
    hack: null,
    link: null,
  };
  obj.str = msg.match(/^\s+$/);
  obj.hack = msg.match(/<\w+>/);
  obj.link = msg.match(/(https:)|(http:)|([A-Za-z0-9а-яёА-ЯЁ\-]{1,}\.[A-Za-zа-яёА-ЯЁ]{2,4})/); //.{1,}\.\D{2,4}
  //console.log(obj);
  return obj;
}

export const formatRegistryDate = () => {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const date = new Date();
  return new Intl.DateTimeFormat('ru-RU', options).format(date).toString();
};

export const formatMsgDate = (timeZone: string) => {
  const options = { timeZone: timeZone, day: '2-digit', month: '2-digit', year: 'numeric' };
  const date = new Date();
  return new Intl.DateTimeFormat('ru-RU', options).format(date).toString();
};

export const formatMsgTime = (timeZone: string) => {
  const options = { timeZone: timeZone, hour: 'numeric', minute: '2-digit' };
  const date = new Date();
  return new Intl.DateTimeFormat('ru-RU', options).format(date).toString();
};

export const formatVisitDate = () => {
  const options = {
    timeZone: mskTimeZone,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  };
  const date = new Date();
  return new Intl.DateTimeFormat('ru-RU', options).format(date).toString();
};

export const formatVisitAdmin = () => {
  const options = {
    timeZone: mskTimeZone,
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  };
  const date = new Date();
  return new Intl.DateTimeFormat('ru-RU', options).format(date).toString();
};

export const getPrevMonth = () => {
  const date = new Date();
  new Date(date.setMonth(date.getMonth() - 1));
  //console.log(date);
  return date;
};

export const getRegistryDate = (date: string) => {
  const newDate = date?.replace(/(\d{1,2}).(\d{1,2}).(\d{4})/, '$3-$2-$1') || '';
  //console.log(new Date(newDate));
  return new Date(newDate);
};

export const genMsgId = (value: string) => {
  const d = new Date().toLocaleDateString().replace(/\./g, '').slice(1);
  return `${value}-${d}${Date.now().toString().slice(4, -2)}`;
};

export const genId = (value: string, adminId?: number) => {
  let n = 1;
  if (!!adminId) n = 0;
  // const d = Date.now().toString().slice(0, 1);
  // if (+d === 0 || +d === 1) n + 1;
  return `${value}-${n}${Date.now().toString()}`;
};

export const checkSubscription = (data: Subscription) => {
  let newPaid = data?.paid;
  let newDate = data?.date;
  let newDeadline = data?.deadline || '';
  const date = new Date();
  const dateDeadline = new Date(data?.deadline);
  const day = 1000 * 60 * 60 * 24;
  const diff = Math.floor(dateDeadline?.getTime() - date.getTime());
  //const diff = new Date(deadline.getTime() - date.getTime());
  let days = Math.floor(diff / day);
  //console.log(days);
  //console.log(deadline < date);
  if (dateDeadline < date) {
    newPaid = false;
    newDate = '';
    newDeadline = '';
    days = 0;
  }

  return {
    subscription: {
      orderId: data.orderId,
      variant: data.variant,
      paid: newPaid,
      date: newDate,
      deadline: newDeadline,
      amountDays: days,
    },
  };
};

export const getVisitDate = () => {
  return new Date().toJSON().slice(0, 10);
};

export const getDates = () => {
  const optionsYear = { timeZone: mskTimeZone, year: 'numeric' };
  const optionsMonth = { timeZone: mskTimeZone, month: '2-digit' };
  const optionsDay = { timeZone: mskTimeZone, day: '2-digit' };
  const date = new Date();

  return {
    year: new Intl.DateTimeFormat('ru-RU', optionsYear).format(date).toString(),
    month: new Intl.DateTimeFormat('ru-RU', optionsMonth).format(date).toString(),
    day: new Intl.DateTimeFormat('ru-RU', optionsDay).format(date).toString(),
  };
};

export const getCodeDb = (code: string) => {
  if (code === 'auth/wrong-password') return 'Неверный пароль';
  else if (code === 'auth/user-not-found') return 'Пользователь не найден';
  else if (code === 'auth/email-already-in-use') return 'Данная почта уже используется';
  else if (code === 'Error') return 'Внутренняя ошибка сервера';
  else if (code === 'PERMISSION_DENIED') return 'ДОСТУП ЗАПРЕЩЕН';
  else return code;
};

// const createArr = (amount: number) => {
//   const arr = [];
//   for (let i = 1; i <= amount; i++) {
//     arr.push(i);
//   }
//   return arr;
// };
