import { RootState } from '../index';

export function getOrders(state: RootState) {
  return state.orders;
}

export function orderPending(state: RootState) {
  return state.orders.request.status === 1;
}

export function orderLoaded(state: RootState) {
  return state.orders.request.status === 2;
}

export function orderFailure(state: RootState) {
  return state.orders.request.error;
}

// export function viewedPhotosPending(state: RootState) {
//   return state.orders.requestViewed.status === 1;
// }

// export function viewedPhotosFailure(state: RootState) {
//   return state.orders.requestViewed.error;
// }
