import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
//import { Badge } from '@mui/material';
import { selectAdmin } from '@/store/admin/selectors';
import { adminLogout } from '@/store/admin/slicer';
import { getUser } from '@/store/profile/selectors';
import { selectMessages } from '@/store/messages/selectors';
import { changeUser, userLogout } from '@/store/profile/slicer';
import { clearPhotos } from '@/store/photos/slicer';
import { getChatsDb, getAllMessagesDb, clearMessages } from '@/store/messages/slicer';
import { clearSympathies } from '@/store/sympathies/slicer';
import { clearOrders } from '@/store/orders/slicer';
import { clearFeedback } from '@/store/feedback/slicer';
import { formatVisitDate } from '@/utils/methods';

// eslint-disable-next-line sonarjs/cognitive-complexity
const Sidebar: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { activated, currUser } = useSelector(getUser, shallowEqual);
  const { authed: adminAuth } = useSelector(selectAdmin, shallowEqual);
  const { chatsList } = useSelector(selectMessages, shallowEqual);

  useEffect(() => {
    function userUnload() {
      dispatch(changeUser({ online: false }));
    }

    if (!!currUser?.id) {
      window.addEventListener('unload', userUnload);
      //window.addEventListener('beforeunload', handleTabClosing)
      dispatch(getChatsDb(currUser.id));
      const obj = {
        online: true,
        visitDate: formatVisitDate(),
      };
      dispatch(changeUser(obj));
    }

    return () => {
      window.removeEventListener('unload', userUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currUser.id]);

  useEffect(() => {
    if (!!currUser?.id && chatsList.length > 0) dispatch(getAllMessagesDb(chatsList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatsList.length]);

  const unreadChat = useMemo(() => chatsList?.find(({ read }) => !read), [chatsList]);
  //console.log(unreadChat);

  const handleExit = () => {
    dispatch(changeUser({ online: false }));
    if (adminAuth) dispatch(adminLogout());
    dispatch(userLogout());

    setTimeout(() => {
      dispatch(clearPhotos());
      dispatch(clearMessages());
      dispatch(clearSympathies());
      dispatch(clearOrders());
      dispatch(clearFeedback());
    }, 600);
  };

  const handleLink = (e: React.MouseEvent) => {
    if (!activated) e.preventDefault();
  };

  // const setBadgeChats = () => {
  //   const list = chatsList?.filter(({ read }) => !read) || [];
  //   //console.log(list.length);
  //   return list.length;
  // };

  const setLinkClass = (path: string) => {
    const allowPath = path === '/questionnaires' || path === '/sympathies';

    if (pathname === path) return 'menu__link menu__link_active';
    else if (allowPath && !activated) return 'menu__link menu__link_hidden';
    else return 'menu__link';
  };

  return (
    <div className="sidebar">
      <nav className="menu">
        <Link to="/profile" className={setLinkClass('/profile')}>
          <div className="image">
            <img
              src="/images/icons/menu-home.svg"
              alt="Профиль"
              className="icon"
              width="35"
              height="36"
            />
          </div>
          <span className="text">Профиль</span>
        </Link>
        <Link to="/questionnaires" className={setLinkClass('/questionnaires')} onClick={handleLink}>
          <div className="image">
            <img
              src="/images/icons/menu-quests.svg"
              alt="Анкеты"
              className="icon"
              width="24"
              height="36"
            />
          </div>
          <span className="text">Анкеты</span>
        </Link>
        <Link to="/messages" className={setLinkClass('/messages')}>
          <div className="image">
            {!unreadChat ? (
              <img
                src="/images/icons/menu-msgs.svg"
                alt="Сообщения"
                className="icon"
                width="30"
                height="36"
              />
            ) : (
              <img
                src="/images/icons/menu-msgs-active.svg"
                alt="Сообщения"
                className="iconMsg"
                width="30"
                height="36"
              />
            )}
          </div>
          <span className="text">Сообщения</span>
          {/* <Badge className="badgeChats" color="primary" badgeContent={setBadgeChats()} /> */}
        </Link>
        <Link to="/sympathies" className={setLinkClass('/sympathies')} onClick={handleLink}>
          <div className="image">
            <img
              src="/images/icons/menu-simp.svg"
              alt="Симпатии"
              className="icon"
              width="35"
              height="36"
            />
          </div>
          <span className="text">Симпатии</span>
        </Link>
        <Link to="/settings" className={setLinkClass('/settings')}>
          <div className="image">
            <img
              src="/images/icons/menu-sett.svg"
              alt="Настройки"
              className="icon"
              width="35"
              height="36"
            />
          </div>
          <span className="text">Настройки</span>
        </Link>
      </nav>

      <div className="exit">
        <button className="btn" aria-label="exit" onClick={handleExit}>
          <img
            src="/images/icons/arrow-prev.svg"
            alt="Выход"
            className="icon"
            width="50"
            height="25"
          />
          <span className="text">Выход</span>
        </button>
      </div>
    </div>
  );
});

export default Sidebar;
